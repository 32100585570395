<template>
  <div class="container commonDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Mine/ExistingEvidence' }"
        >存证列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>存证详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="commonWhiteBg">
      <div class="topSteps">
        <el-steps :active="orderInfo.step">
          <el-step title="支付">
            <div slot="description">
              {{
                orderInfo.status == "10" ? "等待支付" : paymentInfo.payTimeText
              }}
            </div>
            <a-icon v-if="orderInfo.status != '10'" slot="icon" type="check" />
            <a-icon v-else slot="icon" type="redo" />
          </el-step>
          <el-step title="上链">
            <template v-if="orderInfo.status == '10'">
              <div slot="description">存证上链</div>
              <a-icon slot="icon" type="exclamation" />
            </template>
            <template v-else-if="orderInfo.status == '11'">
              <div slot="description">处理中，请耐心等待</div>
              <a-icon slot="icon" type="redo" />
            </template>
            <template
              v-else-if="orderInfo.status == '13' || orderInfo.status == '21'"
            >
              <div slot="description">{{ orderInfo.chainTimeText }}</div>
              <a-icon slot="icon" type="check" />
            </template>
          </el-step>
          <el-step title="存证证书">
            <template v-if="orderInfo.status == '21'">
              <div slot="description">{{ orderInfo.updateTimeText }}</div>
              <a-icon slot="icon" type="check" />
            </template>
            <template v-else>
              <div slot="description">存证成功，获得证书</div>
              <a-icon slot="icon" type="exclamation" />
            </template>
          </el-step>
        </el-steps>
        <div class="rightMain">
          <div>
            <span class="label"> 订单编号：</span>
            <span class="value"> {{ orderNo }} </span>
          </div>
          <div>
            <span class="label"> 支付方式：</span>
            <span class="value">
              {{ orderInfo.paymodeText }}（{{ orderInfo.orderAmount }}元）</span
            >
          </div>
          <div class="btnWrap" v-if="orderInfo.status == '10'">
            <el-button type="primary" size="mini" @click="onGoPay"
              >立即支付</el-button
            >
          </div>
          <div class="btnWrap" v-else>
            <router-link :to="{ path: '/Register/InputForm' }">
              <el-button type="primary" size="mini">新增登记</el-button>
            </router-link>
            <router-link :to="{ path: '/Monitor/InputForm' }">
              <el-button type="primary" size="mini">新增监测</el-button>
            </router-link>
            <router-link :to="{ path: '/ObtainEvidence/InputForm' }">
              <el-button type="primary" size="mini">新增取证</el-button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="pdfShow" v-if="orderInfo.status == '21'">
        <div>
          <div class="commonTitle">存证证书</div>
          <p class="tittle">温馨提示：</p>
          <p class="desc">
            1、使用中国知链微信小程序，扫描二维码可查验证书真伪；<br />
            2、通过中国知链防伪查询网址可查验真伪。<br />
          </p>
          <div class="btnWrap">
            <el-button type="primary" size="mini" @click="downloadCertificate"
              >下载存证证书</el-button
            >
          </div>
        </div>
        <div>
          <el-image
            class="img"
            v-if="orderInfo.certificateInfo"
            :src="orderInfo.certificateInfo.jpgUrl"
            :preview-src-list="[orderInfo.certificateInfo.jpgUrl]"
          >
          </el-image>
        </div>
      </div>

      <div class="commonTitle mt32">存证信息</div>
      <div>
        <el-form ref="form" :model="form" label-width="140px">
          <el-form-item label="存证名称" prop="name">
            <div class="nameRow">
              {{ orderInfo.subject }}
              <router-link
                :to="{
                  path: '/ExistingEvidence/FileList',
                  query: { orderNo: orderNo,bizType:'exist' },
                }"
              >
                查看存证文件
              </router-link>
              <!-- <a-tooltip>
              <template slot="title">
                prompt text
              </template>
              <a-icon type="info-circle" />
            </a-tooltip> -->
            </div>
          </el-form-item>
          <el-form-item label="区块链联盟公示" prop="publicity">
            {{ orderInfo.transactionHash || "-" }}
          </el-form-item>
          <el-form-item label="存证数据包校验值" prop="checkValue">
            {{
              orderInfo.certificateInfo
                ? orderInfo.certificateInfo.body.data.md5Code
                : "-"
            }}
            <a class="ml16"> 查看 </a>
          </el-form-item>
          <el-form-item label="校验算法" prop="value1">
            MD5
            <!-- {{form.value1}} -->
          </el-form-item>
          <el-form-item label="存证数量" prop="value2">
            {{ orderInfo.items.length }}个文件
            <!-- <router-link
              class="ml16"
              :to="{path:'/ExistingEvidence/FileList',query: {'orderNo':orderNo}}"
            >
            查看
             </router-link> -->
          </el-form-item>
          <el-form-item label="订单编号" prop="value3">
            {{ orderNo }}
          </el-form-item>
          <el-form-item label="提交存证时间" prop="value4">
            {{ orderInfo.createTimeText }}
          </el-form-item>
          <el-form-item label="存证证书下发时间" prop="value5">
            {{ orderInfo.updateTimeText }}
          </el-form-item>
          <el-form-item label="支付方式" prop="value6">
            {{ orderInfo.paymodeText }}
          </el-form-item>
          <el-form-item label="支付金额（元）" prop="value7">
            {{ orderInfo.orderAmount }}
          </el-form-item>
        </el-form>
      </div>
    </div>
    <pay-dialog ref="payDialog" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import {
  queryExistOrder,
  queryPaymentOrderByOutBizNp,
  paymentExistOrder
} from "@/api/EvidenceService";
// import PayDialog from "@/views/multipleService/components/PayDialog";
import PayDialog from "../../components/PayDialog";
import constants from "@/utils/constants";
export default {
  name: "ExistingEvidenceDetail",
  components: { PayDialog },
  data() {
    return {
      stepIdx: 3, // 0 带支付, 1处理中,3 完成
      orderNo: this.$route.query.orderNo,
      orderInfo: {
        items: [],
      },
      paymentInfo: {},
      form: {
        name: "小王子的玫瑰花",
        publicity: "-",
        checkValue: "-",
        value1: "SHA256",
        value2: "1个文件",
        value3: "123290428309489",
        value4: "2020-11-27 17:04:54",
        value5: "2020-11-27 17:04:54",
        value6: "支付宝支付",
        value7: "10",
      },
    };
  },
  mounted() {
    this.loadOrder();
  },
  methods: {
    getStep(status) {
      let step = 0;
      if (status == "10") {
        step = 0;
      } else if (status * 1 < 15) {
        step = 1;
      } else {
        step = 2;
      }
      return step;
    },
    downloadCertificate() {
      window.open(this.orderInfo.certificateInfo.pdfUrl);
    },
    loadOrder() {
      console.log("存证id：",this.orderNo);
      queryExistOrder(this.orderNo).then(({ data }) => {
        console.log("存证详情：",data);
        this.orderInfo = data;
        console.log(constants);
        this.orderInfo.createTimeText = this.dayjs(
          this.orderInfo.createTime * 1000
        ).format("YYYY-MM-DD HH:mm:ss ");
        this.orderInfo.updateTimeText = this.dayjs(
          this.orderInfo.updateTime * 1000
        ).format("YYYY-MM-DD HH:mm:ss ");
        this.orderInfo.paymodeText = constants.paymode[this.orderInfo.paymode];
        this.orderInfo.step = this.getStep(this.orderInfo.status);
        if (this.orderInfo.status != "10") {
          queryPaymentOrderByOutBizNp(this.orderNo, "1").then(({ data }) => {
            this.paymentInfo = data;
            this.paymentInfo.payTimeText = this.dayjs(
              this.paymentInfo.updateTime * 1000
            ).format("YYYY-MM-DD HH:mm:ss ");
          });
          if(this.orderInfo.status!='21'){
            setTimeout(this.loadOrder,30*1000);
          }
        }
      });
    },
    onGoPay() {
      let that = this;
      paymentExistOrder(that.orderNo).then(({data})=>{
            that.$refs.payDialog.showDialog(data);
            that.isPayDialogVisible = true;
      })
    
    },
    onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        this.loadOrder();
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/css/inputForm.scss";
</style>
